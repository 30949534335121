import React from "react";
import "./App.css";
import { Row, Col, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  faCogs,
  faUsers,
  faCode,
  faBrain,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
// TODO
/*
 . Update documentation and configuration details.
 . pop up email func
 . make icons bigger and move them around
 . make links to website
 . add arrow to bottom of hero that links to next section
 . create footer with context
 . adjust for screen sizes
 . ssl
 . title icons

*/
const Hero = () => {
  const [pos, setPos] = useState(100);

  const changePos = () => {
    if (pos === 100) {
      setPos(120);
    } else {
      setPos(100);
    }
  };
  useEffect(() => {
    // if (auto) {
    const autoInterval = setInterval(async () => {
      changePos();
    }, 800);

    //clean up
    return () => {
      clearInterval(autoInterval);
    };
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pos]);

  return (
    <Container fluid id="hero-container" style={{ marginTop: '2%'}}>
      <Row style={{ width: "100%" }}>
        <Col>
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="WebsiteRow-img"
              src={process.env.PUBLIC_URL + "/images/ecare_logo 1.png"}
              alt={""}
            />
          </div>
        </Col>
      </Row>

      <div>
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ position: "relative", top: pos }}
          className="heroIcon tester"
        />
      </div>
    </Container>
  );
};

const About = () => {
  return (
    <Container fluid id="about-container" style={{ marginBottom: '5%'}}>
      <Row>
        <Col lg={10} id="about-container-text-sec">
          <div className="mb-4">
            <h1 id="about-container-header">Creation Starts With an Idea</h1>
          </div>
          <div className="mb-5">
            <p id="about-container-text">
              We are a growing software development company 
              that focuses on providing solutions to data management problems. Ecare specializes in
              building web applications that are easily accessible across all
              platforms.
              <br />
              <br />
              We look to bring an emphasis on data management to all industries.
              We aim to bring ideas to life, large and small, through
              collaboration, innovation and problem solving.
            </p>
            <Button style={{ backgroundColor: "#15478e" }}>
              <a
                style={{ color: "white" }}
                href="mailto:admin.support@ecare-software.com?subject=Prospective Client"
                target={"_blank"}
              >
                Lets Connect
              </a>
            </Button>
          </div>
        </Col>
        <Col lg={2} className="text-center">
          <div>
            <FontAwesomeIcon
              icon={faCode}
              className="pageIcon"
              id="faLightbulb"
            />
          </div>
          <div>
            <FontAwesomeIcon icon={faCogs} className="pageIcon" id="faCogs" />
          </div>
          <div>
            <FontAwesomeIcon icon={faUsers} className="pageIcon" id="faHome" />
          </div>
          <div>
            <image src />
            <FontAwesomeIcon icon={faBrain} className="pageIcon" id="faBrain" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const WebsiteDisplay = ({ websites }) => {
  const WebsiteRow = ({ website, flip = false }) => {
    const { title, description, imgSrc, link } = website;
    return flip ? (
      <Row className="WebsiteRow flipped">
      {/* <Col lg={6} className="WebsiteRow-left-content">
        <div className="text-center">
          <img className="WebsiteRow-img" src={imgSrc} alt="website" />
        </div>
      </Col> */}
      <Col lg={6}>
        <div className="text-center">
          <a className="websiteTile" href={link} target="_blank" rel="noopener noreferrer">
            <img className="WebsiteRow-img site" src={imgSrc} alt="website" />
          </a>
        </div>
      </Col>
      <Col lg={6} className="WebsiteRow-right-content">
        <div className="mb-4">
          <h2>
            <a className="websiteTile" href={link} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </h2>
        </div>
        <div>
          <p className="website-description">{description}</p>
        </div>
      </Col>
    </Row>
    
    ) : (
      <Row className="WebsiteRow">
        <Col lg={6} className="WebsiteRow-left-content">
          <div className="mb-4">
            <h2>
              <a className="websiteTile" href={link} target="_blank">
                {title}
              </a>
            </h2>
          </div>
          <div>
            <p className="website-description">{description}</p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="text-center">
            <a className="websiteTile" href={link} target="_blank">
              <img className="WebsiteRow-img site" src={imgSrc} alt={""} />
            </a>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <Container fluid id="website-container">
      {websites.map((website, idx) => (
        <WebsiteRow website={website} flip={idx % 2 !== 0} />
      ))}
    </Container>
  );
};

function App() {
  const WEBSITES = [
    {
      title: "Mentor Global",
      description:
        "Mentor Global is a groundbreaking initiative poised to revolutionize student success across multiple universities. Focused on supporting first-generation students, our platform fosters a culture of mentorship, creating a connected community that enhances academic outcomes and personal growth. The platform, driven by cutting-edge technology, streamlines mentorship processes, ensuring that students receive tailored guidance for a successful academic journey",
      link: "",
      imgSrc: process.env.PUBLIC_URL + "/images/mentor_global.png",
    },
    {
      title: "eCare Residential",
      description:
        "e-Care Residential aims to provide a simple, functional software solution for both large and small scale behavior residential care facilities.",
      link: "https://ecare-residential.com/",
      imgSrc: process.env.PUBLIC_URL + "/images/ecare-res.png",
    },
    {
      title: "Mr. Well-Travelled",
      description:
        "Mr. Well-Travelled: Guide to the Afroverse has everything you need to explore, unify, and co-create worlds.",
      link: "https://www.mrwelltravelled.com/",
      imgSrc: process.env.PUBLIC_URL + "/images/travelled.png",
    },
    {
      title: "Next Up",
      description:
        "A better voter experience by assisting with wait time visibility. Next Up aims to open up opportunities for ethical voting practices.",
      link: "https://nextupcityguide.azurewebsites.net/about",
      imgSrc: process.env.PUBLIC_URL + "/images/next_up_city.png",
    },
    {
      title: "demarcuskennedy.com",
      description:
        "Personal blog / portfolio website created for a content developer. Extensive customization.",
      link: "http://demarcuskennedy.com/",
      imgSrc: process.env.PUBLIC_URL + "/images/demarcus.png",
    },
    {
      title: "Onyx Strong",
      description:
        "A store created for a personal trainer that enables their customers to shop for catered workout programs that fit their needs.",
      link: "http://onyxstrong.com/",
      imgSrc: process.env.PUBLIC_URL + "/images/kierra.png",
    },
  ];

  return (
    <>
      <Hero />
      <About />
      <WebsiteDisplay websites={WEBSITES} />
      <div className="text-center mb-5 pb-5">
        <a
          style={{
            color: "rgb(89, 89, 89)",
          }}
          href="mailto:admin.support@ecare-software.com?subject=Prospective Client"
          target={"_blank"}
        >
          Creation Starts With an Idea
          <br />
          admin.support@ecare-software.com
        </a>
      </div>
    </>
  );
}

export default App;
